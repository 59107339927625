@import '../../styles/colours.scss';
@import '../../styles/variables.scss';
@import '../../styles/buttons.scss';

$height: $bottom-menu-height;

.content {
	max-height: 120px;
	min-height: $height;
	height: 10%;
	width: 100%;

	* {
		box-sizing: border-box;
	}

}

.base-menu {
	height: 100%;
	width: 100%;
	display: flex;
	background-color: color(black);
	justify-content: space-between;
	position: relative;
	z-index: 500;
	border-top: 1px solid white;

	button {
		background-color: transparent;
		color: white;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		align-items: center;
		margin: auto;

		img {
			max-height: 70%;
			height: $height/ 2;
			max-width: 100%;
		}

		span {
			max-height: 30%;
		}
	}
}

.section {
	min-width: 80px;
}


.slide-menu {
    position: absolute;
    width: 100%;
    background: black;
    z-index: 50;
    transition: bottom 1s, top 1s;
    bottom: -120px;
		margin: 0;
		padding: 0;
		color: white;
		list-style-type: none;

		&--open {
			bottom: 0;
		}

		li {
			button {
				color: white;
				font-size: 1.5rem;
				text-align: center;
				padding: 15px;
				background: none;
				border: none;
				width: 100%;

				img {
					max-height: 2.5rem;
					padding-right: 10px;
				}
			}
		}

		&::after {
			content: '';
			display: block;
			max-height: 120px;
			min-height: 80px;
			height: 10vh;
			width: 100%;
		}
}
