
@import '../../styles/general.scss';

.app-body {
	height: 100%;
	overflow: hidden;
}

@media(min-width: 800px) {
	.app-body {
		width: 800px;
		margin: auto;
	}
}
