
@import 'colours.scss';

button,
%button {
	background-color: color(focus, background);
	color: color(focus, font);
	padding: 10px;
	border: 1px solid color(blue, darker);
	border-radius: 5px;
	box-shadow: 2px 2px 2px 1px rgba(color(blue, darker), 0.2);

}

%button-link {
	background-color: transparent;
	border: none;
	box-shadow: none;
}
