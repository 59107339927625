
@import '../../../node_modules/normalize.css/normalize.css'; 
@import 'variables.scss';
@import 'colours.scss';
@import 'buttons.scss';

/*
 * Base styles
 * ========================================================================== */

html {
  color: #222;
  font-weight: 100;
  font-size: 1em; /* ~16px; */
  font-family: $font-family-base;
  line-height: 1.375; /* ~22px */
	background-color: color(bg, light);
	height: 100%;
}

 body {
	 height: 100%;
 }

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/*
 * Browser upgrade prompt
 * ========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}


:global(#app) {
	height: 100%;
}
