@import '../../styles/colours.scss';
@import '../../styles/variables.scss';
@import '../../styles/buttons.scss';

$icon-size: 30px;
$max-width: 800px;
$bottom-menu-height: 80px !default;

$colours: (
	"red": color(red),
	"blue": color(blue),
	"black": color(black),
	"yellow": color(yellow)
);


%picture-btn {

			width: 150px;
			height: 150px;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			background-color: transparent;
			border: none;
			box-shadow: none;

			text-align: center;
			padding-top: 80%;
			color: black;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
}

.game {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;

	h1 {
		text-align: center;
		margin: 0.2em;
	}
}

:root {
	--ball-colour: white;


    --heading-height: 50px;
    --menu-height: #{$bottom-menu-height};
    --player-height: calc((100vh - var(--heading-height) - var(--menu-height))/5);
    --player-width: calc(100vw - 4px);
    --player-button-width: calc(var(--player-width)/4);
    --roquet-height: calc(var(--player-button-width) * 0.3);
 
}

.player {


	@each $ball, $colour in $colours {
		&--#{$ball} {
			--ball-color: $colour;

			.ball {
				background-color: $colour;

				--lightness: lightness($colour);
				--white: lightness(#000);
				--black: lightness(#fff);

				//@if lightness($colour) > 90 {
				@if $ball == "yellow" {
					color: black;
				}  @else {
					color: white;
				}
			}
		}


	}

	display: flex;
	flex-direction: row;
	flex-wrap: no-wrap;
	align-items: center;
	justify-content: space-between;


    max-height: var(--player-height);
	width: var(--player-width);


	&--active {
		background-color: white;
		box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.5);
	}


	.hoop {
		width: var(--player-button-width);
		height: 100%;
		text-align: center;

		button {
			width: 100%;
			height: 100%;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url('./images/hoop.svg');
			background-color: transparent;
			border: none;
			box-shadow: none;

			text-align: center;
			padding-top: 80%;
			color: black;
		}

	}



	.ball {
		max-height: var(--player-button-width);
		width: var(--player-button-width);
		aspect-ratio: 1/1;
		border-radius: 50%;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		font-size: calc(var(--player-button-width) * 0.65);
        font-weight: bold;

		display: flex;
		align-items: center;
		justify-content: center;

		> button {
			width: 100%;
			height: 100%;
			background: none;
			border: none;
			box-shadow: none;

			color: inherit;
		}


		
	}

	.roquets {
		width: 30px;
		text-align: left;

		h2 {
			font-size: 0;
			line-height: 0;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {
			width: var(--roquet-height);
			height: var(--roquet-height);
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			font-size: 0;
			line-height: 0;
		}

		.roquet {
			&--black {
				background-image: url('./images/ball-black.svg');
			}
			&--blue {
				background-image: url('./images/ball-blue.svg');
			}
			&--red {
				background-image: url('./images/ball-red.svg');
			}
			&--yellow {
				background-image: url('./images/ball-yellow.svg');
			}
		}

	}

}


.nextBtn {
	width: 100px;
	max-height: var(--player-height);
	button {
		width: 100px;
		height: 100%;
		@extend %picture-btn;
		background-size: auto 75%;
		background-position-y: 10%;
		background-image: url('./images/next-shot.svg');


	}
}
